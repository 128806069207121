
import { Component, Mixins, Prop } from 'vue-property-decorator';
import CircledAvatar from '@/components/ui/CircledAvatar.vue';
import ConfirmationDialog from '@/components/ui/ConfirmationDialog.vue';
import { AssignmentModel } from '@/models/course';
import { VuetifyMixin, BAIconsMixin } from '@/mixins';
import { AssignmentStatus } from '@/../types/enums';

@Component({
	components: { CircledAvatar, ConfirmationDialog },
})
export default class MiniAssignmentChip extends Mixins(VuetifyMixin, BAIconsMixin){

	@Prop({ default: false, type: Boolean }) private enableReminders: boolean;
	get AllowReminder(): boolean{
		return this.enableReminders && this.assignment.status === "Not Started";
	}
	@Prop({ default: false, type: Boolean }) private dark: boolean;
	@Prop({ default: 20 }) private height: number;
	@Prop() assignment: AssignmentModel;

	get TextColor(){
		return this.getColor(this.CanClick ? 'baColorVibrantBlue' : 'baColorPrimaryText', this.dark) ;
	}

	get CanClick(){
		return this.assignment.status !== AssignmentStatus.NotStarted;
	}

	showRemindDialog: boolean = false;
	get AssignmentName(): string{
		if(!this.assignment) return "";
		return this.assignment.name;
	}
	confirmRemindStudent(): void{
		this.showRemindDialog = true;
	}
	emitRemindStudent(): void{
		this.$emit('remind');
		this.showRemindDialog = false;
	}
}
